<template>
  <v-col cols="12">
    <v-card v-if="checkLenght == 0">
      <v-card v-if="isForm">
        <v-card-title>ข้อมูลกำกับภาษี</v-card-title>
        <v-card-subtitle>ระบุรายละเอียดใบกำกับภาษีได้ที่นี่</v-card-subtitle>
        <v-container class="text-center py-11">
          <v-icon size="100" color="#D8DCDF">mdi-file</v-icon>
          <div class="py-10">ยังไม่มีข้อมูลใบกำกับภาษี</div>
          <v-btn small style="color: white" color="#00205C" @click="isForm = false">เพิ่มข้อมูลกำกับภาษี</v-btn>
        </v-container>
      </v-card>
      <v-card v-else>
        <v-card-title>ข้อมูลกำกับภาษี</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation class="multi-col-validation">
            <v-row id="form-address">
              <v-col cols="12" md="6">
                <v-text-field type="text" v-model="full_name" :rules="rules" required outlined dense
                  placeholder="ชื่อร้าน"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field type="number" v-model="tax_number" :rules="idCardRules" required outlined dense
                  placeholder="หมายเลขผู้เสียภาษี"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field type="number" v-model="phone" :rules="rulesPhone" required outlined dense
                  placeholder="เบอร์โทร"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="address" :rules="rules" outlined dense placeholder="ที่อยู่" required>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <ThailandAutoComplete v-model="zipcode" type="zipcode" @select="select" color="#35495e"
                  placeholder="รหัสไปรษณีย์" />
              </v-col>

              <v-col cols="12" md="6">
                <ThailandAutoComplete v-model="district" type="district" @select="select" color="#35495e"
                  placeholder="ตำบล" class="test" />
              </v-col>

              <v-col cols="12" md="6">
                <ThailandAutoComplete v-model="amphoe" type="amphoe" @select="select" color="#35495e"
                  placeholder="อำเภอ" />
              </v-col>

              <v-col cols="12" md="6">
                <ThailandAutoComplete v-model="province" type="province" @select="select" color="#35495e"
                  placeholder="จังหวัด" />
              </v-col>

              <v-col cols="12">
                <v-btn color="primary" :disabled="!valid || checkAddress" @click="handleCreate"> บันทึก </v-btn>
                <v-btn class="ml-3" @click="isForm = true"> ยกเลิก </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-card>
    <div v-else>
      <v-card>
        <div>
          <v-card-title>
            ข้อมูลกำกับภาษี<v-spacer></v-spacer>
            <!-- <v-btn small @click="dialogLogo = true" style="color: white" color="#00205C" class="mr-2">อัปโหลด</v-btn> -->
            <v-btn small @click="dialog = true" style="color: white" color="#00205C">แก้ไข</v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <br />
          <v-row>
            <v-col cols="3">
              <v-img :src="logoBranch" class="ma-3 ml-10" width="230" />
            </v-col>
            <v-col cols="9" class="align-self-end">
              <v-card-text class="font-weight-semibold">ชื่อร้าน : {{ detailsAddress.full_name }} </v-card-text>
              <v-card-text>หมายเลขผู้เสียภาษี {{ detailsAddress.tax_number }} </v-card-text>
              <v-card-text>{{ detailsAddress.address }} </v-card-text>
              <v-card-text>ต.{{ detailsAddress.district }} อ.{{ detailsAddress.amphure }} จ.{{ detailsAddress.province
              }}
                {{ detailsAddress.zip_code }}
              </v-card-text>
              <v-card-text>โทรศัพท์ {{ detailsAddress.phone }} </v-card-text>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <v-card class="my-5">
        <div>
          <v-card-title>
            ลายเซ็น<v-spacer></v-spacer>
            <v-btn small @click="dialogLicense = true" style="color: white" color="#00205C">แก้ไข</v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <br />
          <div class="d-flex align-center">
            <div>
              <v-img class="ma-3 ml-10" contain v-if="img_license_show" :src="img_license_show" width="230" />
              <v-img class="ma-3 ml-10" v-else src="../../assets/images/logos/text_saijai.png" width="230" />
            </div>
            <div class="px-3">
              <v-card-text class="font-weight-semibold mx-16">ชื่อ : {{ name_license_text }} </v-card-text>
            </div>
          </div>
        </div>
      </v-card>

      <!-- address -->
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title> แก้ไขที่อยู่ </v-card-title>
          <v-card-text>
            <v-form ref="formEdit" v-model="validEdit" lazy-validation class="multi-col-validation">
              <v-row id="form-address">
                <v-col cols="12" md="6">
                  <v-text-field type="text" v-model="detailsAddress.full_name" :rules="rules" required outlined dense
                    placeholder="ชื่อร้าน"></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field type="number" v-model="detailsAddress.tax_number" :rules="idCardRules" required outlined
                    dense placeholder="หมายเลขผู้เสียภาษี"></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field type="number" v-model="detailsAddress.phone" :rules="rulesPhone" required outlined dense
                    placeholder="เบอร์โทร"></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field v-model="detailsAddress.address" :rules="rules" outlined dense placeholder="ที่อยู่"
                    required></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <ThailandAutoComplete v-model="detailsAddress.zip_code" type="zipcode" @select="selectEdit"
                    color="#35495e" placeholder="รหัสไปรษณีย์" />
                </v-col>

                <v-col cols="12" md="6">
                  <ThailandAutoComplete v-model="detailsAddress.district" type="district" @select="selectEdit"
                    color="#35495e" placeholder="ตำบล" class="test" />
                </v-col>

                <v-col cols="12" md="6">
                  <ThailandAutoComplete v-model="detailsAddress.amphure" type="amphoe" @select="selectEdit"
                    color="#35495e" placeholder="อำเภอ" />
                </v-col>

                <v-col cols="12" md="6">
                  <ThailandAutoComplete v-model="detailsAddress.province" type="province" @select="selectEdit"
                    color="#35495e" placeholder="จังหวัด" />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="handleCancel"> ยกเลิก </v-btn>
            <v-btn color="primary" :disabled="!validEdit || checkAddressEdit" @click="handleEdit"> บันทึก </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- logo -->
    <v-dialog v-model="dialogLogo" persistent max-width="400px">
      <v-card>
        <v-card-title> อัปโหลดโลโก้ </v-card-title>
        <v-card-text>
          <v-img :src="img_preview"></v-img>
        </v-card-text>
        <v-card-text>
          <v-file-input placeholder="เลือกไฟล์รูปภาพ" prepend-icon="mdi-image" small-chips @change="onFileChange">
          </v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCancelLogo"> ยกเลิก </v-btn>
          <v-btn color="primary"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- license -->
    <v-dialog v-model="dialogLicense" persistent max-width="400px">
      <v-card>
        <v-card-title> อัปโหลดลายเซ็น </v-card-title>
        <v-card-text>
          <v-text-field v-model="name_license" label="ชื่อ-สกุล"></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-img :src="img_preview_license"></v-img>
        </v-card-text>
        <v-card-text>
          <v-file-input placeholder="เลือกไฟล์รูปภาพ" prepend-icon="mdi-image" small-chips
            @change="onFileChangeLicense"></v-file-input>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCancelLicense"> ยกเลิก </v-btn>
          <v-btn color="primary" @click="handleConfirmLicense"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import ThailandAutoComplete from 'vue-thailand-address-autocomplete'
import config from '../../../config'
import { configcode } from '@/config/bzbsconfig.js'
export default {
  components: {
    ThailandAutoComplete,
  },
  data() {
    return {
      rules: [v => !!v || 'กรุณากรอกข้อมูล'],
      rulesPhone: [v => !!v || 'กรุณากรอกข้อมูล', v => v.length <= 10 || 'เบอร์โทร 9-10 ตัวอักษร'],
      idCardRules: [v => !!v || 'กรุณากรอกข้อมูล', v => v.length == 13 || 'หมายเลขผู้เสียภาษี 13 ตัว'],
      rulesLogo: [v => !!v || 'กรุณาเลือกรูปภาพ'],

      valid: false,
      validEdit: false,
      validLogo: false,
      full_name: '',
      tax_number: '',
      phone: '',
      address: '',
      amphoe: '',
      district: '',
      province: '',
      zipcode: '',
      show: false,
      checkLenght: '',
      detailsAddress: [],
      dialog: false,
      dialogLogo: false,
      dialogLicense: false,
      logoBranch: localStorage.getItem('logo_branch'),
      isForm: true,
      filesLogo: '',
      img_preview: '',
      name_license: '',
      name_license_text: 'xxx xxxxx',
      img_license: '',
      img_preview_license: '',
      img_license_show: '',
    }
  },
  mounted() {
    this.getClinicAddress()
    this.getSignature()
  },
  methods: {
    handleCreate() {
      if (this.$refs.form.validate()) {
        var data = {
          full_name: this.full_name,
          tax_number: this.tax_number,
          phone: this.phone,
          address: this.address,
          district: this.district,
          amphure: this.amphoe,
          province: this.province,
          zip_code: this.zipcode,
        }

        this.$store.dispatch('createClinicAddress', data).then(res => {
          console.log(res)
          this.getClinicAddress()
        })
      }
    },
    select(address) {
      this.district = address.district
      this.amphoe = address.amphoe
      this.province = address.province
      this.zipcode = address.zipcode
    },
    selectEdit(address) {
      this.detailsAddress.district = address.district
      this.detailsAddress.amphure = address.amphoe
      this.detailsAddress.province = address.province
      this.detailsAddress.zip_code = address.zipcode
    },

    getClinicAddress() {
      this.$store.dispatch('getClinicAddress').then(res => {
        console.log(res.length)
        this.checkLenght = res.length
        this.detailsAddress = res[0]
      })
    },
    handleEdit() {
      if (this.$refs.formEdit.validate()) {
        var data = {
          id: this.detailsAddress.id,
          full_name: this.detailsAddress.full_name,
          tax_number: this.detailsAddress.tax_number,
          phone: this.detailsAddress.phone,
          address: this.detailsAddress.address,
          district: this.detailsAddress.district,
          amphure: this.detailsAddress.amphure,
          province: this.detailsAddress.province,
          zip_code: this.detailsAddress.zip_code,
        }
        this.$store.dispatch('editClinicAddress', data).then(res => {
          console.log(res)
          this.getClinicAddress()
          this.dialog = false
        })
      }
    },
    handleCancel() {
      this.getClinicAddress()
      this.dialog = false
    },
    onFileChange(e) {
      console.log(e)
      if (e != undefined) {
        this.img_preview = URL.createObjectURL(e)
        this.filesLogo = e
      } else {
        this.img_preview = ''
        this.filesLogo = ''
      }
    },
    onFileChangeLicense(e) {
      console.log(e)
      if (e != undefined) {
        this.img_preview_license = URL.createObjectURL(e)
        this.img_license = e
      } else {
        this.img_preview_license = ''
        this.img_license = ''
      }
    },
    handleCancelLogo() {
      this.img_preview = ''
      this.filesLogo = ''
      this.dialogLogo = false
    },
    handleConfirmLicense() {
      this.dialogLicense = false
      this.img_license_show = this.img_preview_license
      this.name_license_text = this.name_license
      const data = {
        name: this.name_license,
        img: this.img_license ? this.img_license : '',
      }
      this.$store.dispatch('createSignature', data).then(res => {
        console.log(res)
        this.img_license_show = ''
        this.name_license_text = ''
        this.img_preview_license = ''

        this.getSignature()
      })
    },
    handleCancelLicense() {
      this.img_preview_license = ''
      this.img_license = ''
      this.dialogLicense = false
    },
    getSignature() {
      this.$store.dispatch('getSignature').then(res => {
        console.log(res)
        this.name_license_text = res.signature_name
        this.name_license = res.signature_name
        this.img_license_show = configcode.basePublic + res.signature_picture
      })
    },
  },
  computed: {
    checkAddress() {
      if (this.zipcode == '' || this.district == '' || this.amphoe == '' || this.province == '') {
        return true
      } else {
        return false
      }
    },
    checkAddressEdit() {
      if (
        this.detailsAddress.zip_code == '' ||
        this.detailsAddress.district == '' ||
        this.detailsAddress.amphure == '' ||
        this.detailsAddress.province == ''
      ) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>
<style>
*[data-v-1f53c317] {
  box-sizing: border-box;
  font-family: 'Noto Sans Thai', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  box-shadow: inset 0 0px 0px hsl(0deg 0% 0% / 0%) !important;
}

.vth-addr-input[data-v-1f53c317] {
  float: left;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  padding: calc(0.595em - 0.5px) 0.75rem;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
  border: solid 1px #e2e2e2 !important;
}

.vth-addr-input-size-default[data-v-1f53c317] {
  font-size: 14px;
}

#form-address ::placeholder {
  color: rgb(151, 151, 151);
}
</style>
